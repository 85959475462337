const grasslakeFall2023option1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-after-school-fridays-115-to-400pm-grass/",
    name: "29 Enrichment Sessions",
    date: "1:15-4:00 PM",
  },
]

const grasslakeFall2023option2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/grass-lake-after-school-fridays-115-to-245pm-grass/",
    name: "29 Enrichment Sessions",
    date: "1:15-2:45 PM",
  },
]
const grasslakeFall2023option1IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/aug-to-sep-grass-lake-after-school-fridays-115-to-/",
    name: "*$100* August 30th",
    date: "September 27th 5 Sessions*",
  },
  {
    link: "https://playyon.com/606-academy/programs/oct-to-nov-grass-lake-after-school-fridays-115-to-/",
    name: "October 4th",
    date: "November 15th",
  },
  {
    link: "https://playyon.com/606-academy/programs/dec-to-jan-grass-lake-after-school-fridays-115-to-/",
    name: "December 6th",
    date: "January 31st",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-7-to-apr-4-grass-lake-after-school-fridays-115/",
    name: "February 7th",
    date: "April 4th",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-11-to-may-23-grass-lake-after-school-fridays-1/",
    name: "April 11th",
    date: "May 23rd",
  },
]

const grasslakeFall2023option2IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/aug-to-sep-grass-lake-after-school-fridays-115-t-2/",
    name: "*$60* August 30th",
    date: "September 27th 5 Sessions*",
  },
  {
    link: "https://playyon.com/606-academy/programs/oct-to-nov-grass-lake-after-school-fridays-115-t-2/",
    name: "October 4th",
    date: "November 15th",
  },
  {
    link: "https://playyon.com/606-academy/programs/dec-to-jan-grass-lake-after-school-fridays-115-t-2/",
    name: "December 6th",
    date: "January 31st",
  },
  {
    link: "https://playyon.com/606-academy/programs/feb-7th-to-apr-4th-grass-lake-after-school-fridays/",
    name: "February 7th",
    date: "April 4th",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-11th-to-may-23rd-grass-lake-after-school-frida/",
    name: "April 11th",
    date: "May 23rd",
  },
]


const grasslakeFall2023AInfo = [
  "Registration extended to 8/28 for Aug to Sep sessions scheduled to start 8/30 pending registrations!",
  "No class held on 8/23, 10/11; 11/22; 11/29; 12/20; 12/27; 1/3; 2/14; 3/21; 3/28; 4/18;",
  "ONLY Grass Lake School students are eligible to sign up for this enrichment program",
  "Individual day registrations are available upon request.",
  "Individual day registration starts at $35 from 1:15 to 2:45PM; $50 from 1:15 to 4:00PM",
  "Held on early release Fridays",
  "Bring a basketball and a water bottle.",
  "K to 8th Grade welcome",
]

const grasslakeFall2023Drills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {grasslakeFall2023Drills, grasslakeFall2023AInfo, grasslakeFall2023option1IndividualSessions, grasslakeFall2023option2IndividualSessions, grasslakeFall2023option1Bundle, grasslakeFall2023option2Bundle}