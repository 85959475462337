import PurchaseListLotus from '../../../components/PurchaseListLotus';
import Location from '../../../components/Location.jsx'
import LogisiticsDetails from '../../../components/LogisiticsDetails';
import 'animate.css';
import LotusLogo from '../../../static/Lotus/LotusLogo.png';
import {lotusFall2023Drills, lotusFall2023AInfo, lotusFall2023option1IndividualSessions, lotusFall2023option2IndividualSessions, lotusFall2023option1Bundle, lotusFall2023option2Bundle} from './LotusSpringTwoData'
import './lotus.scss'
import '../../../App.scss'

export default function Lotus221to320() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
    <div className="info camps__container animate__animated animate__fadeInRight">
      <h4>Friday Enrichment at Lotus School - Grades K-4</h4>
      <h4 className='disclaimer'>This afterschool program is for Lotus School Students only</h4>
      <div className='lotus__container'>
        <div className="purchase">
          <PurchaseListLotus 
            type="bundle-option1"
            header="Entire School Year 12:45-4:00pm"
            subhead="1 payment of $550 // Over $100 in savings!"
            data={lotusFall2023option1Bundle}
          />
          <PurchaseListLotus 
            type="bundle-option2"
            header="Enitre School Year 12:45-2:20pm"
            subhead="1 payment of $350 // Over $55 in savings!"
            data={lotusFall2023option2Bundle}
          />
          <PurchaseListLotus
            type="individual-option1"
            header="6 Classes from 12:45-4:00pm"
            subhead="$115/ 6 Sessions"
            data={lotusFall2023option1IndividualSessions}
          />
          <PurchaseListLotus 
            type="individual-option2"
            header="6 Classes from 12:45-2:20pm"
            subhead="$72/ 6 Sessions"
            data={lotusFall2023option2IndividualSessions}
          />

        </div>

        <div className='logistics'>
        <img
              className="img-fluid mb-4 pl-0"
              src={LotusLogo}
              alt="Lotus Logo"
            />
          <Location 
            link="https://www.gls36.org/"
            name="Lotus School"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={lotusFall2023AInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={lotusFall2023Drills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}