const lotusFall2023option1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/34-enrichment-session-1245-to-400pm-september/",
    name: "34 Enrichment Sessions",
    date: "1:15-4:00 PM",
  },
]

const lotusFall2023option2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/34-enrichment-session-1245-to-220pm-september/",
    name: "34 Enrichment Sessions",
    date: "1:15-2:45 PM",
  },
]

const lotusFall2023option1IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/sept-4th-to-oct-9th-6-enrichme/",
    name: "September 4th",
    date: "October 9th",
  },
  {
    link: "https://playyon.com/606-academy/programs/oct-16th-to-nov-20th-6-enrichment-sessions-1245-to/",
    name: "October 16th",
    date: "November 20th",
  },
  {
    link: "https://playyon.com/606-academy/programs/dec-4th-to-jan-22nd-6-enrichment-sessions-1245-to-/",
    name: "December 4th",
    date: "January 22nd",
  },
  {
    link: "https://playyon.com/606-academy/programs/jan-29th-to-march-5th-6-enrich/",
    name: "January 29th",
    date: "March 5th",
  },
  {
    link: "https://playyon.com/606-academy/programs/mar-12th-to-april-23rd-6-enric/",
    name: "March 12th",
    date: "April 23rd",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-30th-to-may-21st-4-enrichment-sessions-1245-to/",
    name: "$77 *** April 30th",
    date: "May 21st *** 4 Sessions",
  },
]

const lotusFall2023option2IndividualSessions = [
  {
    link: "https://playyon.com/606-academy/programs/sept-4th-to-oct-9th-6-enrichment-sessions-1245-to-/",
    name: "September 4th",
    date: "October 9th",
  },
  {
    link: "https://playyon.com/606-academy/programs/oct-16th-to-nov-20th-6-enrichment-sessions-1245-2/",
    name: "October 16th",
    date: "November 20th",
  },
  {
    link: "https://playyon.com/606-academy/programs/dec-4th-to-jan-22nd-6-enrichme/",
    name: "December 4th",
    date: "January 22nd",
  },
  {
    link: "https://playyon.com/606-academy/programs/jan-29th-to-mar-5th-6-enrichment-sessions-1245-to-/",
    name: "January 29th",
    date: "March 5th",
  },
  {
    link: "https://playyon.com/606-academy/programs/mar-12th-to-apr-23rd-6-enrichment-sessions-1245-to/",
    name: "March 12th",
    date: "April 23rd",
  },
  {
    link: "https://playyon.com/606-academy/programs/apr-30th-to-may-21st-4-enrichment-sessions-1245-2/",
    name: "$48 *** April 30th",
    date: "May 21st *** 4 Sessions",
  },
]


const lotusFall2023AInfo = [
  "Registration closes one week prior to start date",
  "No class held on 11/27, 12/25. 1/1, and 3/26;",
  "ONLY Lotus School students are eligible to sign up for this enrichment program",
  "Individual day registrations are available upon request.",
  "Individual day registration starts at $35 from 1:15 to 2:45PM; $50 from 1:15 to 4:00PM",
  "Held on early release Wednesdays",
  "Bring a basketball and a water bottle.",
  "K to 4th Grade welcome",
]

const lotusFall2023Drills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {lotusFall2023Drills, lotusFall2023AInfo, lotusFall2023option1IndividualSessions, lotusFall2023option2IndividualSessions, lotusFall2023option1Bundle, lotusFall2023option2Bundle}