import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.scss';

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import HomePage from './pages/HomePage/HomePage.js';
import AboutUs from './pages/AboutUs/AboutUs';
import Values from './pages/AboutUs/Values';
import Team from './pages/AboutUs/Team';
import ContactUs from './pages/ContactUs/ContactUs.js';
import Feedback from './pages/Feedback/Feedback.js';

import Enrichment from './pages/Enrichment/General/Enrichment';

import GrassLakeSpring from './pages/Enrichment/Grasslake/GrassLakeSpring';
import LotusSpringTwo from './pages/Enrichment/Lotus/LotusSpringTwo'


import CampsAndClinics from './pages/CampsAndClinics/CampsAndClinics';
import WeekCamps from './pages/CampsAndClinics/WeekCamps';
import Holiday from './pages/CampsAndClinics/Holiday';
import Peewee from './pages/CampsAndClinics/Peewee';

import Training from './pages/Training/Training0';
import Training1 from './pages/Training/Training1';
import Training2 from './pages/Training/Training2';
import Training3 from './pages/Training/Training3';
import Training4 from './pages/Training/Training4';
import Training5 from './pages/Training/Training5';
import Training6 from './pages/Training/Training6';

class App extends Component {
  
  render() {
    return (
      <BrowserRouter>
        <div className="background">
            <Header />
            <Route exact path="/" component={HomePage} />
            <Route path="/About" component={AboutUs} />
            <Route path="/Values" component={Values} />
            <Route path="/Team" component={Team} />
            <Route path="/Enrichment" component={Enrichment} />

            <Route path="/CampsAndClinics" component={CampsAndClinics} />
            
            <Route path="/Training" component={Training} />
            <Route path="/Training1" component={Training1} />
            <Route path="/Training2" component={Training2} />
            <Route path="/Training3" component={Training3} />
            <Route path="/Training4" component={Training4} />
            <Route path="/Training5" component={Training5} />
            <Route path="/Training6" component={Training6} />



            <Route path="/606atGLS" component={GrassLakeSpring}/>
            <Route path="/606atLotus" component={LotusSpringTwo}/>
            
            <Route path="/Peewee" component={Peewee} />
            <Route path="/Holiday" component={Holiday} />
            <Route path="/WeekCamps" component={WeekCamps} />
            <Route path="/ContactUs" component={ContactUs} />
            <Route path="/Feedback" component={Feedback} />
        </div>
        <Footer />

      </BrowserRouter>
    );
  }
}

export default App;