import {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption, trainingOption1Bundle, trainingOption2Bundle} from './Training4Data'
import PurchaseList from '../../components/PurchaseList';
import PurchaseListNavTraining from '../../components/PurchaseListNavTraining';
import Location from '../../components/Location.jsx'
import LogisiticsDetails from '../../components/LogisiticsDetails';
import 'animate.css';

export default function Training4() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Basketball Training Grades 3 to Highschool Feb 25 to April 3rd</h4>
        <div className='camp__container'>
          <div className="purchase">
          <PurchaseListNavTraining url="Training4" />
          <PurchaseList 
            type="ThreeDayBundle"
            header="3 Day Bundle Tue-Wed-Thurs Feb to Apr"
            subhead="15 Classes for 1 payment of $250 //  $50 in savings!"
            data={trainingOption1Bundle}
          />
          <PurchaseList 
            type="TwoDayBundle"
            header="2 Day Bundle Tue-Wed-Thurs Feb to Apr"
            subhead="10 Classes for 1 payment of $185 // $15 in savings!"
            data={trainingOption2Bundle}
          />
          <PurchaseList
            type="Tuesday"
            header="Tuesday Training from 6:00-7:30pm"
            subhead="$100/ 5 Classes"
            data={tuesdayTrainingOption}
          />
          <PurchaseList
            type="Wednesday"
            header="Wednesday Training from 6:00-7:30pm"
            subhead="$100/ 5 Classes"
            data={wednesdayTrainingOption}
          />
            <PurchaseList
            type="Thursday"
            header="Thursday Training from 6:00-7:30pm"
            subhead="$100/ 5 Classes"
            data={thursdayTrainingOption}
          />

        </div>

        <div className='logistics'>
        <Location 
            link="https://www.d114.org/elementary-school"
            name="Lotus School"
            street="29067 W Grass Lake Rd"
            city="Fox Lake, IL 60020"
          />
          <LogisiticsDetails 
            header="Important Notes"
            data={trainingInfo}
          />
          <LogisiticsDetails 
            header="Skills & Drills Focus"
            data={trainingDrills}        
          />
        </div>

      </div>
      {/* close camp container div */}
    </div>
    {/* close info div */}
  </section>
)
}