import PurchaseListGrasslake from '../../../components/PurchaseListGrasslake';
import Location from '../../../components/Location.jsx'
import LogisiticsDetails from '../../../components/LogisiticsDetails';
import 'animate.css';
import GrassLakeLogo from '../../../static/GrassLake/GrassLakeLogo.png';
import {grasslakeFall2023Drills, grasslakeFall2023AInfo, grasslakeFall2023option1IndividualSessions, grasslakeFall2023option2IndividualSessions, grasslakeFall2023option1Bundle, grasslakeFall2023option2Bundle} from './GrassLakeSpringData'
import './grasslake.scss'
import '../../../App.scss'

export default function GrassLakeSpring() {
  return (
    <section className="section d-flex flex-column align-items-center justify-content-center">
      <div className="info camps__container animate__animated animate__fadeInRight">
        <h4>Friday Enrichment at Grass Lake School - Grades K-8</h4>
        <h4 className='disclaimer'>No Class on 8/23! - Registration Extended to 8/28!</h4>
        <h4 className='disclaimer'>This afterschool program is for Grass Lake School Students only</h4>
        <div className='grasslake__container'>
          <div className="purchase">
            <PurchaseListGrasslake 
              type="bundle-option1"
              header="Entire School Year 1:15-4:00pm"
              subhead="1 payment of $480 // $75 in savings!"
              data={grasslakeFall2023option1Bundle}
            />
            <PurchaseListGrasslake 
              type="bundle-option2"
              header="Enitre School Year 1:15-2:45pm"
              subhead="1 payment of $303 // $45 in Savings!"
              data={grasslakeFall2023option2Bundle}
            />
            <PurchaseListGrasslake 
              type="individual-option1"
              header="6 Classes from 1:15-4:00pm"
              subhead="$115/ 6 Sessions"
              data={grasslakeFall2023option1IndividualSessions}
            />
            <PurchaseListGrasslake 
              type="individual-option2"
              header="6 Classes from 1:15-2:45pm"
              subhead="$72/ 6 Sessions"
              data={grasslakeFall2023option2IndividualSessions}
            />

          </div>

          <div className='logistics'>
          <img
                className="img-fluid mb-4 pl-0"
                src={GrassLakeLogo}
                alt="Antioch Logo"
              />
            <Location 
              link="https://www.gls36.org/"
              name="Grass Lake School"
              street="26177 W. Grass Lake Rd,"
              city="Antioch, IL 60002"
            />
            <LogisiticsDetails 
              header="Important Notes"
              data={grasslakeFall2023AInfo}
            />
            <LogisiticsDetails 
              header="Skills & Drills Focus"
              data={grasslakeFall2023Drills}        
            />
          </div>

        </div>
        {/* close camp container div */}
      </div>
      {/* close info div */}
    </section>
  )
}