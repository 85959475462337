const trainingOption1Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "3 Day Bundle M/W/F",
    date: "6:00PM to 7:30 PM",
  },
]

const trainingOption2Bundle = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "2 Day Bundle M/W/F",
    date: "6:00PM to 7:30 PM",
  },
]

const tuesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "January 21st",
    date: "February 18th",
  },
]


const wednesdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "January 22nd",
    date: "February 19th",
  },
]

const thursdayTrainingOption = [
  {
    link: "https://playyon.com/606-academy/programs/training-jan-21-to-feb-20-tues-wed-thurs/",
    name: "January 23rd",
    date: "February 20th",
  },
]


const trainingInfo = [
  "Registration closes one week prior to start date",
  "Individual day registration starts at $45 and available upon request",
  "Bring a basketball and a water bottle.",
  "3rd Grade to 11th Grade",
]

const trainingDrills = [
  "Triple Threat",
  "Stationary Ball Handling",
  "Ball Handling",
  "Attacking the Basket",
  "Layups",
  "Form Shooting",
  "Footwork",
  "Transition",
  "Three Man Weave",
  "Passing",
  "Passing Off the Dribble",
  "Defense",
  "Rebounding",
  "and more!"
]



export {trainingDrills, trainingInfo, tuesdayTrainingOption, wednesdayTrainingOption, thursdayTrainingOption,trainingOption1Bundle, trainingOption2Bundle}