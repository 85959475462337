const navbarLinks = [
  {
    category: "Home",
    link: "/",
    links: [],
  },
  {
    category: "About Us",
    link: "#",
    links: [
      {
        name: "Our Story",
        url: "/About",
      },
      {
        name: "Our Values",
        url: "/Values",
      },
    ],
  },
  {
    category: "Training Tues/Wed/Thu",
    link: "#",
    links: [
      {
        name: "Sep 24 to Oct 24",
        url: "/Training",
      },
      {
        name: "Oct 29 to Nov 24",
        url: "/Training1",
      },
      {
        name: "Dec 3 to Jan 16",
        url: "/Training2",
      },
      {
        name: "Jan 21 to Feb 20",
        url: "/Training3",
      },
      {
        name: "Feb 25 to Apr 3",
        url: "/Training4",
      },
      {
        name: "April 8 to May 1",
        url: "/Training5",
      },
      {
        name: "May 6 to May 28",
        url: "/Training6",
      },
    ],
  },
  {
    category: "Camps",
    link: "#",
    links: [
      {
        name: "1 Day Holiday Camps",
        url: "/Holiday",
      },
      {
        name: "Week Long School Break Camps",
        url: "/WeekCamps",
      },
      {
        name: "Peewee Clinics - Ages 3-5",
        url: "/Peewee",
      },
    ],
  },
  {
    category: "Enrichment",
    link: "#",
    links: [
      {
        name: "What we offer",
        url: "/Enrichment", 
      },
    ],
  },
  {
    category: "Grass Lake School",
    link: "#",
    links: [
      {
        name: "Friday After-School Club",
        url: "/606atGLS", 
      },
    ],
  },
  {
    category: "Lotus School",
    link: "#",
    links: [
      {
        name: "Wednesday After-School Club",
        url: "/606atLotus", 
      },
    ],
  },
  {
    category: "Contact Us",
    link: '/ContactUs',
    links: [],
  }
];


export { navbarLinks };